<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Payment</a></li>
    </ol>
    <h1 class="page-header">PAYMENT</h1>
    <div>
      <div v-html="$error.handle(error)" />
      <ul
          class="nav nav-tabs"
      >
        <li class="nav-item">
          <a
              :class="show === 1 ? 'active' : ''"
              class="nav-link"
              @click.prevent="show = 1"
          ><i class="fa fa-mobile" /> Pay via MPESA</a>
        </li>
        <li class="nav-item">
          <a
              :class="show === 2 ? 'active' : ''"
              @click.prevent="show = 2"
              class="nav-link"
          ><i class="fa fa-bank" /> Pay via CoOperative Online Banking</a>
        </li>
      </ul>
      <div
          class="tab-content bg-white p-10"
      >

        <div
            v-if="show === 1"
            class="tab-pane fade"
            :class="show === 1 ? 'active show' : ''"
        >
          <div class="mpesa">
            <div class="row">
              <div class="col-md-6 border-right">
                <div class="p-5">
                  <h6>Option One</h6>
                  <p>
                    Enter your phone number (07XXXXXXXX) to receive a payment request on your phone. All
                    you have to do is input your m-pesa pin.
                  </p>
                  <form
                      data-vv-scope="form1"
                      @submit.prevent="validateForm('form1')"
                  >
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                          v-model="stk_phone_number"
                          v-validate="'required|digits:10'"
                          :class="{'is-invalid': errors.has('form1.phone')}"
                          class="form-control"
                          data-vv-scope="form1"
                          name="phone"
                          type="number"
                      >
                      <div :class="{'help-block': errors.has('form1.phone')}">
                        {{ errors.first('form1.phone') }}
                      </div>
                    </div>
                    <div
                        v-if="!exceeds_mpesa"
                        class="form-group"
                    >
                      <a
                          v-if="stk_waiting"
                          class="btn btn-primary pull-right btn-loading"
                          href="#"
                          @click.prevent="requestSTK"
                      >
                        <i class="fa fa-spin fa-spinner" />
                        Waiting for payment...
                      </a>
                      <button
                          v-else
                          class="btn btn-primary pull-right"
                          type="submit"
                      >
                        Request Payment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div class="col-md-6">
                <div class="p-5">
                  <h6>Follow the instructions below:</h6>
                  <ul>
                    <li>Go to m-pesa menu</li>
                    <li>Select lipa na mpesa</li>
                    <li>Select pay bill</li>
                    <li>Enter paybill number <strong>{{ mpesa_paybill }}</strong></li>
                    <li>Enter account number <strong>BP{{ account }}</strong></li>
                    <li>Enter the amount <strong>KES {{ $number.format(amount) }}</strong></li>
                    <li>Enter your pin and send</li>
                  </ul>
                  <p>
                    Once you receive the confirmation SMS, enter the transaction code in the box
                    below
                  </p>
                  <form
                      data-vv-scope="form2"
                      @submit.prevent="validateForm('form2')"
                  >
                    <div class="form-group">
                      <label>Transaction Code</label>
                      <input
                          v-model="transaction_code"
                          v-validate="'required'"
                          :class="{'is-invalid': errors.has('form2.transaction_code')}"
                          class="form-control"
                          name="transaction_code"
                          placeholder="XXXXXX"
                          type="text"
                      >
                      <div :class="{'help-block': errors.has('form2.transaction_code')}">
                        {{ errors.first('form2.transaction_code') }}
                      </div>
                    </div>
                    <div class="form-group">
                      <a
                          v-if="loading"
                          class="btn btn-primary pull-right btn-loading"
                          href="#"
                          @click.prevent="confirmTrx"
                      ><i
                          class="fa fa-spin fa-spinner"
                      /> Verifying payment</a>
                      <button
                          v-else
                          class="btn btn-primary pull-right"
                          type="submit"
                      >
                        Verify
                        Payment
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
            v-if="show === 2"
            class="tab-pane fade"
            :class="show === 2 ? 'active show' : ''"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="p-5">
                <p><strong>Account: </strong> BP{{ account }}</p>
                <p><strong>Amount: </strong> KES {{ $number.format(amount) }} </p>
                <p><a :href="online_banking_url" class="btn btn-outline-primary" target="_blank">Go to Online Banking <i
                    class="fa fa-chevron-right"></i></a></p>
                <p>
                  Once you have completed the payment, enter the transaction code in the box
                  below
                </p>
                <form
                    data-vv-scope="form2"
                    @submit.prevent="validateForm('form2')"
                >
                  <div class="form-group">
                    <label>Transaction Code</label>
                    <input
                        v-model="transaction_code"
                        v-validate="'required'"
                        :class="{'is-invalid': errors.has('form2.transaction_code')}"
                        class="form-control"
                        name="transaction_code"
                        placeholder="XXXXXX"
                        type="text"
                    >
                    <div :class="{'help-block': errors.has('form2.transaction_code')}">
                      {{ errors.first('form2.transaction_code') }}
                    </div>
                  </div>
                  <div class="form-group">
                    <a
                        v-if="loading"
                        class="btn btn-primary pull-right btn-loading"
                        href="#"
                        @click.prevent="confirmTrx"
                    ><i
                        class="fa fa-spin fa-spinner"
                    /> Verifying payment</a>
                    <button
                        v-else
                        class="btn btn-primary pull-right"
                        type="submit"
                    >
                      Verify
                      Payment
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: 'Loading ...',
      error: '',
      stk_phone_number: '',
      transaction_code: '',
      amount: '',
      balance: 0,
      company: '',
      can_pay: false,
      stk_waiting: false,
      loading: false,
      show: 1,
    }
  },
  computed: {
    account() {
      return this.$route.params.id;
    },
    online_banking_url() {
      return process.env.VUE_APP_ONLINE_BANKING_URL;
    },
    exceeds_mpesa() {
      return this.amount > process.env.VUE_APP_MPESA_LIMIT;
    },
    mpesa_paybill() {
      return process.env.VUE_APP_MPESA_PAYBILL;
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.$axios.post('/api/v1/bid-bonds/cost', {
        secret: this.$route.params.id
      }).then(response => {
        this.amount = response.data.total;
      });
    },

    validateForm(scope) {
      return this.$validator.validateAll(scope).then(result => {
        if (result) {
          if (scope === 'form1') this.requestSTK();
          if (scope === 'form2') this.confirmTrx();
        }
      });
    },
    requestSTK() {
      this.error = "";
      this.stk_waiting = true;
      this.$axios.post('/api/v1/initiate-stk', {
        phone: this.stk_phone_number,
        account: 'BP' + this.account,
        amount: this.amount
      }).then(() => {
        this.$toastr.i("A push notification of KES " + this.amount + " for payment has been sent to " + this.stk_phone_number);
        this.confirmPayment();
      }).catch(err => {
        this.stk_waiting = false;
        this.error = err.response;
        this.$toastr.e(err.response.data.error.message);
      });
    },
    confirmPayment() {
      const self = this;
      let attempts = 0;
      self.loading = true;
      let interval = setInterval(function () {
        self.$axios.post('/api/v1/confirm-payment', {
          account: 'BP' + self.account
        }).then(response => {
          if (response.data.confirmed) {
            self.stopInterval(interval);
            self.checkExists();
          } else {
            attempts += 1;
            if (attempts === 10) {
              self.stopInterval(interval);
              attempts = 0;
              self.$toastr.e("Took too long to perform payment.Retry confirm", "Timeout");
            }
          }
        }).catch(err => {
          self.stopInterval(interval);
          this.error = err.response;
        });
      }, 5000);
    },
    stopInterval(interval) {
      this.stk_waiting = false;
      this.loading = false;
      clearInterval(interval);
    },
    confirmTrx() {
      this.error = "";
      this.loading = true;
      this.$axios.post('/api/v1/confirm-transaction', {
        account: 'BP' + this.account,
        transaction_code: this.transaction_code
      }).then(response => {
        if (response.data.confirmed) {
          this.checkExists();
        } else {
          this.loading = false;
          this.$toastr.e(response.data.error.message, "Payment error");
        }
      }).catch(err => {
        this.loading = false;
        this.error = err.response;
      });
    },
    checkExists() {
      this.$toastr.i("Bid Bond payment successfully received!");
      this.notifyChecking();
      const self = this;
      let attempts = 0;
      let interv = setInterval(function () {
        self.$axios.post('/api/v1/bid-bonds/exists', {
          secret: self.account
        }).then(response => {
          if (response.data.exists) {
            clearInterval(interv);
            self.$toastr.i("Bid bond generation successfully!");
            self.$router.push({
              name: 'bidbonds.show',
              params: {id: self.$route.params.id}
            });
          } else {
            attempts += 1;
            if (attempts === 10) {
              clearInterval(interv);
              attempts = 0;
              self.$toastr.e("Bid Bond processing took too long.If you do not receive the bid bond in your email in the next 5 minutes contact support", "Bidbond Delay");
              self.$router.push({name: 'bidbonds.index'});
            } else {
              self.notifyChecking();
            }
          }
        }).catch(err => {
          self.stopInterval(interv);
          self.error = err.response;
        });
      }, 6000);
    },
    notifyChecking() {
      this.$swal({
        title: 'Bid Bond Processing',
        text: "Waiting for bid bond to complete processing ...",
        icon: 'info',
        timer: 6000,
        timerProgressBar: true,
      });
    }
  }
}
</script>

<style lang="scss">
#company-payment {
  .mpesa {
    padding: 20px 0;

    .manual {
      border-left: 1px dotted #aaa;
      padding-left: 20px;

      @media (max-width: 480px) {
        border-top: solid 1px #ddd;
        margin-top: 30px;
        padding-top: 10px;
        border-left: none;
        padding-left: 0;
      }
    }
  }
}
</style>